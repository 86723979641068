<template>
  <div
    class="modalPopup print_modal order_detail_print_modal"
    v-if="modalClose"
  >
    <div class="modal_header">
      <h2 class="title">원자재 발주 현황</h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
      <ul class="option">
        <li>
          <h6>구매처</h6>
          {{ getCompanyName }}
        </li>
        <li>
          <h6>입고요청일</h6>
          {{ managementData.incoming_date }}
        </li>
        <li>
          <h6>발주일</h6>
          {{ managementData.input_date }}
        </li>
        <li>
          <h6>입고 완료여부</h6>
          {{ managementData.completed ? 'Y' : 'N' }}
        </li>
        <li>
          <h6>비고</h6>
          {{ managementData.detail }}
        </li>
      </ul>
    </div>
    <div class="modal_body"></div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  props: ['managementData', 'getCompanyName', 'getBarcodeNumber'],
  data() {
    return {
      modalClose: true,
    };
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');
      const orderDetailHeader = document.querySelector(
        '.order_detail_print_modal .modal_header',
      );
      const orderDetailTable = document.querySelector(
        '.order_detail_print_modal .modal_body .mes_tbl_wrap',
      );
      const printDiv = document.createElement('div');
      let orderDetailHeaderClone = orderDetailHeader.cloneNode(true);
      let orderDetailTableClone = orderDetailTable.cloneNode(true);
      printDiv.className = 'order_detail_print_wrap';
      document.body.appendChild(printDiv);
      printDiv.append(orderDetailHeaderClone, orderDetailTableClone);
      app.style.display = 'none';
      window.print();
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
  },
  mounted() {
    let orderDetailModal = document.querySelector(
      '.order_detail_print_modal .modal_body',
    );
    let orderDetailTable = document.querySelector('.order_detail_print_table');
    let orderDetailTableClone = orderDetailTable.cloneNode(true);
    orderDetailModal.appendChild(orderDetailTableClone);
  },
  async created() {},
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: A4 !important;
    margin: auto 0 !important;
  }
  @page :first {
    margin-top: 0 !important;
  }
}
</style>
