<template>
  <div
    class="modalPopup print_modal purchase_form_print_modal"
    v-if="modalClose"
  >
    <div class="modal_header">
      <h2 class="title">발주서</h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <div class="purchase_form_print_table">
        <h2>발주서</h2>
        <div class="company_info_wrap">
          <table>
            <tbody>
              <tr>
                <td rowspan="5" class="company_info">
                  <p class="companyName">
                    {{ findInfoFromId(companys, getCompanyId).name }} 귀하
                  </p>
                  <p>발주일자 : {{ managementData.input_date }}</p>
                  <p>
                    전화번호 :
                    {{ findInfoFromId(companys, getCompanyId).phone_number }}
                  </p>
                  <p>
                    팩스번호 :
                    {{ findInfoFromId(companys, getCompanyId).fax_number }}
                  </p>
                  <p>제목</p>
                  <p><strong>납기일 내에 인도해 주시기 바랍니다.</strong></p>
                </td>
                <td rowspan="5" class="text_center_bold">
                  발주자
                </td>
                <td class="text_center">등록번호</td>
                <td colspan="4">
                  {{ systemCompany.saup_no }}
                </td>
              </tr>
              <tr>
                <td class="text_center">상호</td>
                <td colspan="2">{{ systemCompany.name }}</td>
                <td class="text_center">성명</td>
                <td colspan="2">{{ systemCompany.representative }}</td>
              </tr>
              <tr>
                <td class="text_center">주소</td>
                <td colspan="4">
                  {{ systemCompany.address }} {{ systemCompany.detail_address }}
                </td>
              </tr>
              <tr>
                <td class="text_center">업태</td>
                <td colspan="2">{{ systemCompany.business_type }}</td>
                <td class="text_center">종목</td>
                <td colspan="2">{{ systemCompany.business_item }}</td>
              </tr>
              <tr>
                <td class="text_center">전화번호</td>
                <td colspan="2">{{ systemCompany.phone_number }}</td>
                <td class="text_center">팩스번호</td>
                <td colspan="2">{{ systemCompany.fax_number }}</td>
              </tr>
            </tbody>
          </table>
          <table class="company_table3">
            <tbody>
              <tr>
                <td>
                  합계금액 : 일금
                  {{
                    $makeKoreanNumber(
                      calTotalPrice(
                        managementData.purchase_order_list,
                        managementData.vat_id,
                        false,
                      ).total_cost,
                    )
                  }}원정 (
                  {{
                    '₩ ' +
                      calTotalPrice(
                        managementData.purchase_order_list,
                        managementData.vat_id,
                        false,
                      ).sales_cost
                  }}) (부가세포함)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mes_tbl_wrap purchase_list">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 8" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>순번</th>
                <th>품목명</th>
                <th>규격</th>
                <th>수량</th>
                <th>단가</th>
                <th>공급가액</th>
                <th>세액</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(purchase_material,
                index) in managementData.purchase_order_list"
                :key="(purchase_material.id, index)"
              >
                <td>{{ index + 1 }}</td>
                <td class="text_left">
                  {{
                    findInfoFromId(materials, purchase_material.material_id)
                      .name || ''
                  }}
                </td>
                <td class="text_left">
                  {{
                    findInfoFromId(materials, purchase_material.material_id)
                      .standard || ''
                  }}
                </td>

                <td class="text_right">
                  <b>
                    {{
                      managementData.purchase_order_list.find(
                        x => x.id == purchase_material.id,
                      ) != undefined
                        ? $makeComma(
                            managementData.purchase_order_list.find(
                              x => x.id == purchase_material.id,
                            ).quantity,
                          )
                        : ''
                    }}
                  </b>
                  {{
                    managementData.purchase_order_list.find(
                      x => x.id == purchase_material.id,
                    ) != undefined
                      ? findInfoFromId(
                          unitCodes,
                          findInfoFromId(
                            materials,
                            purchase_material.material_id,
                          ).incoming_unit_id,
                        ).name
                      : ''
                  }}
                </td>
                <td class="text_right">
                  &#8361;
                  {{
                    managementData.purchase_order_list.find(
                      x => x.id == purchase_material.id,
                    ).cost
                  }}
                </td>
                <td class="text_right">
                  &#8361;
                  {{
                    calSupplyTax(
                      managementData.vat_id,
                      purchase_material.tax_id,
                      $makeNumber(purchase_material.quantity),
                      $makeNumber(purchase_material.cost),
                    ).supply | makeComma
                  }}
                </td>
                <td class="text_right">
                  &#8361;
                  {{
                    calSupplyTax(
                      managementData.vat_id,
                      purchase_material.tax_id,
                      $makeNumber(purchase_material.quantity),
                      $makeNumber(purchase_material.cost),
                    ).tax | makeComma
                  }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colspan="3" class="text_center_bold">합계</td>
                <td colspan="2"></td>
                <td class="cost_text_right">
                  {{
                    '₩ ' +
                      $makeComma(
                        suplyTaxTotalCost(managementData.purchase_order_list),
                      )
                  }}
                </td>
                <td class="cost_text_right">
                  {{
                    '₩ ' +
                      $makeComma(
                        taxTotalCost(managementData.purchase_order_list),
                      )
                  }}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="ect_info">
          <table>
            <tbody>
              <tr>
                <td class="text_center">납기일자</td>
                <td colspan="3">{{ managementData.incoming_date }}</td>
              </tr>
              <tr>
                <td class="text_center">비고</td>
                <td colspan="3">{{ managementData.detail }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';

import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import PriceMixin from '@/mixins/material_price';
import { mapGetters } from 'vuex';

export default {
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN, PriceMixin],
  props: ['managementData', 'getCompanyId'],
  data() {
    return {
      modalClose: true,
    };
  },
  computed: {
    ...mapGetters({
      systemCompany: 'getSystemCompany',
      materials: 'getMaterial',
      companys: 'getCompany',
      unitCodes: 'getUnitCodes',
      purchase_order_list: 'getManagementDataPurchaseMaterialsFromPurchase',
    }),
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');

      const purchaseTable = document.querySelector(
        '.purchase_form_print_modal .modal_body .purchase_form_print_table',
      );
      const printDiv = document.createElement('div');
      let purchaseTableClone = purchaseTable.cloneNode(true);
      printDiv.className = 'purchase_form_print_wrap';
      document.body.appendChild(printDiv);
      printDiv.append(purchaseTableClone);
      app.style.display = 'none';
      window.print();
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
    taxTotalCost(list) {
      return list.map(x => x.tax).reduce((a, b) => this.$decimalAdd(a, b), 0);
    },
    suplyTaxTotalCost(list) {
      return list
        .map(x => x.supply_value)
        .reduce((a, b) => this.$decimalAdd(a, b), 0);
    },
  },
  async created() {
    if (this.systemCompany.id == undefined) {
      await this.FETCH('FETCH_SYSTEM_COMPANY', '사업자 정보');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.unitCodes.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: A4 !important;
    margin: auto 0 !important;
  }
  @page :first {
    margin-top: 0 !important;
  }
}
</style>
