<template>
  <div class="article" @submit.prevent>
    <div class="tbl_option">
      <!-- <div class="tbl_opt"> -->
      <h5>발주 등록</h5>
      <div class="search_opt">
        <label>구매처</label>
        <div class="input_search">
          <input
            type="text"
            placeholder="구매처 검색"
            :readonly="newData.company_id != null"
            :value="getCompanyInfo(newData.company_id).name"
            disabled
          />
          <button>
            <i class="fa fa-search" @click="showCompanySearch = true"></i>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <div class="tbl_head">
      <span>발주내역</span>
    </div>
    <div class="mes_tbl_wrap">
      <table
        class="mes_tbl"
        :class="{ new: newData.purchase_materials.length > 0 }"
      >
        <colgroup>
          <col
            v-for="(n, index) in newData.purchase_materials.length > 0 ? 10 : 9"
            :key="index"
          />
        </colgroup>
        <thead>
          <tr>
            <th>원자재명</th>
            <th>규격</th>
            <th>과세여부</th>
            <th>단위</th>
            <th>수량</th>
            <th>단가</th>
            <th>공급가</th>
            <th>부가세</th>
            <th>합계액</th>
            <th v-if="newData.purchase_materials.length > 0">삭제</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(purchase_material, index) in newData.purchase_materials"
            :key="index"
          >
            <td class="text_left">
              {{ getMaterialInfo(purchase_material.material_id).name || '' }}
            </td>
            <td class="text_left">
              {{
                getMaterialInfo(purchase_material.material_id).standard || ''
              }}
            </td>
            <td>
              {{
                getMaterialInfo(purchase_material.material_id).tax == true
                  ? '과세'
                  : '면세'
              }}
            </td>
            <td>
              {{ getUnitName(purchase_material.unit_id) || '' }}
            </td>
            <td>
              <input
                id="quantity"
                type="text"
                :value="$makeComma(purchase_material.quantity)"
                inputmode="decimal"
                @input="$inputNumber($event, purchase_material, 'quantity')"
              />
            </td>
            <td>
              <input
                id="unit_price"
                type="text"
                :value="$makeComma(purchase_material.cost)"
                inputmode="decimal"
                @input="$inputNumberInt($event, purchase_material, 'cost')"
              />
            </td>
            <!-- @input="typingCost($event, index)" -->
            <td class="text_right">
              &#8361;
              {{
                calSupplyTax(
                  getCompanyInfo(newData.company_id).vat,
                  getMaterialInfo(purchase_material.material_id).tax,
                  $makeNumber(purchase_material.quantity),
                  $makeNumber(purchase_material.cost),
                ).supply | makeComma
              }}
            </td>
            <td class="text_right">
              &#8361;
              {{
                calSupplyTax(
                  getCompanyInfo(newData.company_id).vat,
                  getMaterialInfo(purchase_material.material_id).tax,
                  $makeNumber(purchase_material.quantity),
                  $makeNumber(purchase_material.cost),
                ).tax | makeComma
              }}
            </td>

            <td>
              <input
                type="text"
                :value="
                  $makeComma(
                    calSupplyTax(
                      getCompanyInfo(newData.company_id).vat,
                      getMaterialInfo(purchase_material.material_id).tax,
                      $makeNumber(purchase_material.quantity),
                      $makeNumber(purchase_material.cost),
                    ).total,
                  )
                "
                inputmode="decimal"
                @blur="
                  $event =>
                    ($event.target.value = $makeComma(
                      calSupplyTax(
                        getCompanyInfo(newData.company_id).vat,
                        getMaterialInfo(purchase_material.material_id).tax,
                        $makeNumber(purchase_material.quantity),
                        $makeNumber(purchase_material.cost),
                      ).total,
                    ))
                "
                @keydown.enter="
                  $event => reCalCost(purchase_material, $event.target.value)
                "
                @keypress="onlyNumber($event)"
                @input="$inputNumberIntNoTarget($event)"
              />
            </td>
            <td
              v-if="newData.purchase_materials.length > 0"
              @click="newData.purchase_materials.splice(index, 1)"
            >
              <button class="tbl_delete_btn">
                delete
              </button>
            </td>
          </tr>

          <tr v-show="newData.company_id != null">
            <td>
              <button @click="showMaterialSearch = true">
                <img src="@/assets/images/icon/icon-floating-search.png" />
              </button>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td v-if="newData.purchase_materials.length > 0"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="date_options">
      <div class="receiving_info">
        <div>
          <div class="input_text">
            <label>과세</label>
            <input
              type="text"
              placeholder=""
              readonly
              :value="
                newData.purchase_materials.length > 0
                  ? '₩ ' +
                    calTotalPrice(
                      newData.purchase_materials,
                      getCompanyInfo(newData.company_id).vat,
                      true,
                    ).tax_total_cost
                  : ''
              "
            />
          </div>
          <div class="input_text">
            <label>비과세</label>
            <input
              type="text"
              placeholder=""
              readonly
              :value="
                newData.purchase_materials.length > 0
                  ? '₩ ' +
                    calTotalPrice(
                      newData.purchase_materials,
                      getCompanyInfo(newData.company_id).vat,
                      true,
                    ).non_tax_total_cost
                  : ''
              "
            />
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>구매액</label>
            <input
              id="purchase_cost"
              type="text"
              placeholder=""
              readonly
              :value="
                newData.purchase_materials.length > 0
                  ? '₩ ' +
                    calTotalPrice(
                      newData.purchase_materials,
                      getCompanyInfo(newData.company_id).vat,
                      true,
                    ).total_cost
                  : ''
              "
            />
          </div>
        </div>
      </div>
      <div class="date_set">
        <div class="input_text">
          <label>입고요청일</label>
          <input
            type="date"
            id="setDate"
            min=""
            placeholder=""
            :value="newData.incoming_date"
            @change="setIncomingDate($event)"
          />
        </div>
        <div class="input_text">
          <label>발주일</label>
          <input
            type="date"
            id="today"
            placeholder=""
            :value="newData.input_date"
            @change="setInputDate($event)"
          />
        </div>
      </div>
      <!-- <div class="input_text">
        <label>입고 완료여부</label>
        <input type="text" placeholder="N" disabled />
      </div> -->
      <div class="text_set">
        <div class="input_text">
          <label>비고</label>
          <textarea
            type="text"
            placeholder="발주서 내용"
            v-model="newData.detail"
          ></textarea>
        </div>
      </div>
      <div class="btn_wrap">
        <button class="btn_sub2" @click="submitForm">발주등록</button>
      </div>
    </div>
    <resource-search
      v-if="showMaterialSearch"
      :filter_type="1"
      @onclose="showMaterialSearch = false"
      @onselect="selectMaterial($event)"
    ></resource-search>
    <company-search
      v-if="showCompanySearch"
      :search_type="2"
      @onclose="showCompanySearch = false"
      @onselect="pushNewCompany($event)"
    ></company-search>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';

import PriceMixin from '@/mixins/material_price';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin, PriceMixin],
  components: {
    CompanySearch,
    ResourceSearch,
  },
  data() {
    return {
      showCompanySearch: false,
      showMaterialSearch: false,
      // showDateInput: false,
    };
  },
  computed: {
    ...mapGetters({
      order_purchase_type_id: 'getOrderPurchaseType',
      newData: 'getNewDataFromPurchase',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      start_date: 'getStartDateFromPurchase',
      end_date: 'getEndDateFromPurchase',
      check_only_not_completed: 'getCheckOnlyNotCompletedFromPurchase',
    }),
    checkTotalCostValid() {
      const calPrice = this.$makeNumber(
        this.calTotalPrice(
          this.lodash.clonedeep(this.newData.purchase_materials),
          this.getCompanyInfo(this.newData.company_id).vat,
          true,
        ).total_cost,
      );
      if (calPrice <= 2147483647) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    reCalCost(row, newPurchaseValue) {
      const total_value = this.$makeNumber(newPurchaseValue);
      console.log('total_value', total_value);
      const company_vat = this.companys.find(
        x => x.id == this.newData.company_id,
      ).vat;
      const product_tax = row.tax_id;
      let rowQuantity = this.$makeNumber(row.quantity);
      if (rowQuantity === 0) {
        row.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
    },
    setInputDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.newData.incoming_date) {
          this.openOneButtonModal(
            '발주일 오류',
            `입고요청일(${this.newData.incoming_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.newData.input_date;
        } else {
          this.newData.input_date = e.target.value;
        }
        this.FETCH_PURCHASE();
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    setIncomingDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.newData.input_date) {
          this.openOneButtonModal(
            '입고요청일 오류',
            `발주일(${this.newData.input_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.newData.incoming_date;
        } else {
          this.newData.incoming_date = e.target.value;
        }
        this.FETCH_PURCHASE();
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    submitForm() {
      if (this.validData()) {
        const payload = this.lodash.clonedeep(this.newData);
        payload.vat_id = this.getCompanyInfo(this.newData.company_id).vat;
        payload.completed = false;
        payload.purchase_ident = this.lodash.clonedeep(
          payload.input_date.replace(/-/gi, ''),
        );
        payload.purchase_materials.forEach(el => {
          el.cost = this.$makeNumber(el.cost);
          el.quantity = this.$makeNumber(el.quantity);
          el.tax_id = this.getMaterialInfo(el.material_id).tax;
          el.supply_value = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getMaterialInfo(el.material_id).tax,
            el.quantity,
            el.cost,
          ).supply;
          el.tax = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getMaterialInfo(el.material_id).tax,
            el.quantity,
            el.cost,
          ).tax;
          el.tax = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getMaterialInfo(el.material_id).tax,
            el.quantity,
            el.cost,
          ).tax;
          el.total_cost = this.calSupplyTax(
            this.getCompanyInfo(this.newData.company_id).vat,
            this.getMaterialInfo(el.material_id).tax,
            el.quantity,
            el.cost,
          ).total;
        });
        console.log(payload);
        this.showSpinner();
        this.$store
          .dispatch('INSERT_PURCHASE_ALL', { data_list: [payload] })
          .then(async () => {
            this.openOneButtonModal(
              '등록 성공',
              '성공적으로 발주를 등록하였습니다.',
            );
            this.$store.commit('setNewDataToPurchase', {
              company_id: null,
              purchase_type_id: 1,
              completed: false,
              incoming_date: null,
              input_date: null,
              detail: '',
              purchase_materials: [],
            });
            this.$store.commit('setSelectedIndexToPurchase', -1);
            this.$store.commit('setMaterialSelectedIndexToInput', -1);
            var date = new Date();
            this.newData.input_date = yyyymmdd(date);
            var date2 = new Date(date.setDate(date.getDate() + 7));
            this.newData.incoming_date = yyyymmdd(date2);
            await this.FETCH_PURCHASE();
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '등록 실패',
              '발주 등록 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async FETCH_PURCHASE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_PURCHASE', {
          start_date: this.start_date,
          end_date: this.end_date,
          check_only_not_completed: this.check_only_not_completed,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.$store.commit('setPurchase', []);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    validData() {
      if (
        $('#purchase_cost')[0].value == undefined ||
        $('#purchase_cost')[0].value.length < 1
      ) {
        this.openOneButtonModal(
          '등록 불가',
          '반드시 하나 이상의 발주품목을 등록하여주십시오.',
        );
        return false;
      } else if ($('#quantity')[0].value == 0) {
        this.openOneButtonModal(
          '등록 불가',
          '수량은 최소 1개 이상이어야 합니다.',
        );
        return false;
      } else if (!this.checkTotalCostValid) {
        this.openOneButtonModal(
          '구매액 초과',
          '1회 발주 최대 금액은<br />20억 이상 발주할 수 없습니다.',
        );
        return false;
      } else {
        if (String($('#purchase_cost')[0].value).includes('-')) {
          this.openOneButtonModal(
            '등록 불가',
            '구매액은 최소 0원 이상이어야 합니다.',
          );
          return false;
        }
        return true;
      }
    },
    selectMaterial(arg) {
      this.newData.purchase_materials.push({
        material_id: arg.id,
        unit_id: arg.incoming_unit_id,
        standard: arg.standard,
        quantity: 0,
        cost: 0, //단가
        supply_value: 0, //공급가
        tax: 0, //부가세
        total_cost: 0, //합계액
        tax_id: arg.tax,
      });
      this.showMaterialSearch = false;
    },
    getMaterialInfo(id) {
      let hit = this.materials.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    pushNewCompany(arg) {
      this.newData.company_id = arg.id;
      this.showCompanySearch = false;
    },
  },
  async created() {
    if (
      this.order_purchase_type_id == undefined ||
      this.order_purchase_type_id.length < 1
    ) {
      await this.FETCH('FETCH_PURCHASE_TYPE', '구매 유형');
    }

    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }

    if (this.newData.input_date == null) {
      var date = new Date();
      this.newData.input_date = yyyymmdd(date);
      var date2 = new Date(date.setDate(date.getDate() + 7));
      this.newData.incoming_date = yyyymmdd(date2);
    }
    this.newData.purchase_type_id = this.order_purchase_type_id[0].id;

    if (this.$route.meta.select != undefined) {
      this.$store.commit(
        'setNewDataCompanyIdToPurchase',
        this.$route.meta.select,
      );

      delete this.$route.meta.select;
    }
  },
};
</script>

<style></style>
